import React, { useState } from "react";

import profimg from "./IMG_6485-scaled-e1685347032993.jpg";

const DrSheetalKalra = () => {
  const [isResearchOpen, setIsResearchOpen] = useState(false);
  const [isPublicationsOpen, setIsPublicationsOpen] = useState(false);
  const [isConferencesOpen, setIsConferencesOpen] = useState(false);
  const [isAwardsandRecognitionsOpen, setIsAwardsandRecognitionsOpen] =
    useState(false);

  const [isAwardsandRecOpen, setIsAwardsandRecOpen] = useState(false);
  return (
    <div className="each-faculty-component-page">
      <div className="faculty-image-container-for-each-page">
        <img src={profimg} alt="Prof-pk-sahoo" />
      </div>
      <h2 className="faculty-name-for-each-container">Dr. Sheetal Kalra</h2>
      <h3 className="facult-designation-for-each-container">
        Associate Professor & Head (SOP), school of physiotherapy
      </h3>
      <div className="faculty-contact-and-research-impact-details-each-container">
        <div className="faculty-contact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">Contact</h3>
          <h4 className="faculty-contact-details-email-for-each-page">
            Email : sheetalkalra [at] dpsru [dot] edu [dot] in
          </h4>
        </div>
        <div className="faculty-research-impact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">
            Research Impact
          </h3>
          <ul
            className="faculty-contact-details-email-for-each-page"
            style={{ paddingLeft: "12%" }}
          >
            <li>
              <a href="https://scholar.google.co.in/citations?user=SGOZYYgAAAAJ&hl=en">
                Google Scholar{" "}
              </a>
            </li>
            <li>
              <a>Scopus</a>
            </li>
            <li>
              <a href="https://orcid.org/0000-0003-3317-4624">ORCID URL</a>
            </li>
          </ul>
        </div>
      </div>

      <h2 className="academic-qualification-heading-for-every-page">
        Academic Qualifications
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
        BPT,MPT,PhD
      </p>
      <h2 className="academic-qualification-heading-for-every-page">
        Academic Experience
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
        20 years
      </p>
      <h2 className="academic-qualification-heading-for-every-page">
        Clinical Experience
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
        6 Months
      </p>

      {/* toggle button */}
      <button
        className="naac-button"
        onClick={() => setIsResearchOpen(!isResearchOpen)}
      >
        Research Area
      </button>

      {isResearchOpen ? (
        <div className="inner-container">
          <p>
            Health and Fitness, Non Communicable diseases, Sports Biomechanics,
            Sports Injury Prevention, Women Health, Ergonomics
          </p>
        </div>
      ) : null}

      {/* Publications */}

      <button
        className="naac-button"
        onClick={() => setIsPublicationsOpen(!isPublicationsOpen)}
      >
        Paper Publications
      </button>

      {isPublicationsOpen ? (
        <div className="inner-container">
          <p>
            <strong>PAPER PUBLICATIONS</strong>
          </p>
          <ol>
            <li>
              Bidhuri D, Kalra S, Saher T, Parveen A, Ajmera P, Miraj M. Current
              concepts of high intensity interval training: a clinical
              commentary. Comparative Exercise Physiology. 2025 Jan
              10;21(1):1-5.
            </li>
            <li>
              Roy N, Sharma P, Kalra S, Singh RK, Rai RH, Rohilla P. A
              Comprehensive Pediatric Assessment and Rehabilitation of Hypotonic
              Cerebral Palsy using ICF Model of Functioning, Disability and
              Health: A Case Study. Cuestiones de Fisioterapia. 2024 Jul
              16;53(02):191-204.
            </li>
            <li>
              Parveen A, Kalra S, Awasthi S, Ajmera P, RAI R, Miraj M, Pawaria
              S, Pal S, Naagar N. Effect of Mat Pilates Intervention on Sleep
              Quality in Adolescent girls: A Single Blinded Randomised
              Controlled Trial. Clinical Epidemiology and Global Health. 2024
              Sep 19:101791.
            </li>
            <li>
              Mehta V, Ajmera P, Kalra S, Miraj M, Gallani R, Shaik RA, Serhan
              HA, Sah R. Human resource shortage in India's health sector: a
              scoping review of the current landscape. BMC Public Health. 2024
              May 21;24(1):1368.
            </li>
            <li>
              Mittal P, Bhadania M, Tondak N, Ajmera P, Yadav S, Kukreti A,
              Kalra S, Ajmera P. Effect of immersive virtual reality-based
              training on cognitive, social, and emotional skills in children
              and adolescents with autism spectrum disorder: A meta-analysis of
              randomized controlled trials. Research in Developmental
              Disabilities. 2024 Aug 1;151:104771.
            </li>
            <li>
              Miraj M, Gupta A, Kalra S, Khanna A, Ajmera P. Biomehanical and
              Postural Evaluation of Optimal Bike Fit for non Traumatic Injury
              Prevention Among Cyclists: A Narrative Review. Journal of
              Musculoskeletal Research. 2024 Jun 14.
            </li>
            <li>
              Tyagi M, Pal S, Yadav J, Kalra S*. Effectiveness of eight-week
              variable resistance training on ball release speed, jump height,
              speed of upper limb performance, and accuracy in young collegiate
              basketball players. Med J DY Patil Vidyapeeth 2024;17:722-9
            </li>
            <li>
              Khandelwal M, Kalra S*, Ajmera P, Hirendra Rai R. EFFECT OF
              CUPPING THERAPY ON PAIN, CARDIORESPIRATORY PARAMETERS, AND LUNG
              FUNCTION IN PATIENTS WITH FIBROMYALGIA SYNDROME. Journal of
              Musculoskeletal Research. 2024 Jun 13:2450007.
            </li>
            <li>
              Mittal P, Kalra S, Dadhich A, Ajmera P. Empowering children for
              better health with child-to-child approach: a systematic
              literature review. Health Education. 2024 May 7.
            </li>
            <li>
              Sharma S, Kalra S,* Rai RH, Parveen A, Raghav D, Naswa R. Effect
              of Aerobic Exercise on Sleep, Depression, and Quality of Life in
              Postmenopausal Women. Journal of Cardiac Critical Care TSS. 2024
              Apr 15;8(2):95-100.
            </li>
            <li>
              Sawant KU, Rai RH, Kalra S, Sharma A, Chahal A, Sharma N. Combined
              Effects of Breathing Exercise and Core Strength Training on
              Fatigue, Abdominal Strength, and Cardiovascular Fitness among
              Subjects with Mechanical Nonspecific Lumbago Using
              Tele-physiotherapy: A Randomized Clinical Trial. Journal of Datta
              Meghe Institute of Medical Sciences University. 2024 Apr
              1;19(2):247-56.
            </li>
            <li>
              Ajmera P, Kalra S, Mohapatra SC, Yadav J. Health and Fitness of
              Postmenopausal Women in Urban versus Rural Gurugram: A
              Cross-Sectional Study. Journal of Mid-life Health. 2022
              Oct;13(4):317.
            </li>
            <li>
              Parveen A, Kalra S*, Jain S. Effects of Pilates on health and
              well-being of women: a systematic review. Bulletin of Faculty of
              Physical Therapy. 2023 Dec;28(1):1-2.
            </li>
            <li>
              Kalra S, Aggarwal G, Pawaria S, Yadav S, Ajmera P. Psychological
              health of postmenopausal women: a bibliometric analysis in the
              recent decade. Climacteric. 2023 Apr 27:1-9.
            </li>
            <li>
              Alam S, Kalra S*, Rai R, Raghav D. Frequency and Associated
              Factors of Physical Activity and Sleep with Depression in College
              Going Students. Journal of the Dow University of Health Sciences
              (JDUHS). 2023 Feb 20;17(1):17-24.
            </li>
            <li>
              Swain L, Kalra S, Rai R, Raghav D. Effect of scapular
              strengthening exercises on rounded shoulder and neck disability
              among college students of Delhi NCR. Comparative Exercise
              Physiology. 2023:1-8.
            </li>
            <li>
              Ajmera P, Miraj M, Kalra S, Goyal RK, Chorsiya V, Shaik RA,
              Alzhrani M, Alanazi A, Alqahtani M, Miraj SA, Pawaria S. Impact of
              telehealth interventions on physiological and psychological
              outcomes in breast cancer survivors: A meta-analysis of randomised
              controlled trials. Frontiers in Oncology. 2022;12.
            </li>
            <li>
              Sharma F, Kalra S*, Rai R, Chorsiya V, Dular S. Work-related
              Musculoskeletal Disorders, Workability and its Predictors among
              Nurses Working in Delhi Hospitals: A Multicentric Survey. Journal
              of Clinical & Diagnostic Research. 2022 Oct 1;16(10).
            </li>
            <li>
              Saran M, Pawaria S, Kalra S. Kinesio taping with ballistic six
              plyometric training on speed, accuracy, target and joint
              proprioception in fast bowlers with glenohumeral instability.
              Comparative Exercise Physiology. 2022 Sep 2;18(4):357-63.
            </li>
            <li>
              PAJNEE K, KALRA S, YADAV J. Effect of Kinesio Taping in Patients
              with Cervical Radiculopathy: A Longitudinal Study. Journal of
              Clinical & Diagnostic Research. 2022 Mar 1;16(3).
            </li>
            <li>
              Kalra S, Ajmera P, Chorsiya V. Telerehabilitation services in
              India: an integrated SWOT and AHP analysis. Int J Physiother.
              2021;8(3):170-6.
            </li>
            <li>
              Kalra S, Miraj M, Ajmera P, Shaik RA, Seyam MK, Shawky GM, Alasiry
              SM, Mohamed EH, Alasiri HM, Alzhrani M, Alanazi Effects of Yogic
              Interventions on Patients Diagnosed With Cardiac Diseases. A
              Systematic Review and Meta-Analysis. Frontiers in Cardiovascular
              Medicine. 2022;9.
            </li>
            <li>
              Pal S, Yadav J, Kalra S. Influence of stress and anxiety in
              sports. JCDR. 2021.YE01-YE05
            </li>
            <li>
              Kalra S*, Yadav J, Ajmera P, Sindhu B, Pal S. Impact of Physical
              activity on physical and mental health of post-menopausal women: a
              systematic review. JCDR,2022;16(2): ye01-ye08
            </li>
            <li>
              Jain R, Kalra S*, Pawaria S. A cross sectional survey on physical
              fitness, mental health and associated factors in mothers of
              children with special needs. Comparative Exercise physiology; CEP;
              0(1);1-8
            </li>
            <li>
              Kalra S*, Chorsiya V, Ajmera P, Pawaria S, Pal S. High prevalence
              of depression among mothers tending children with special needs in
              Gurugram India. Asian Journal of Psychiatry. 2021:64:102790
            </li>
            <li>
              PAL S, YADAV J, SINDHU B, KALRA S. Effect of Plyometrics and
              Pilates Training on Dynamic Balance and Core Strength of Karate
              Players. Journal of Clinical & Diagnostic Research. 2021 Jan
              1;15(1).
            </li>
            <li>
              Roshni, Kalra S*, Yadav J. A comparative study of wobble board and
              Bosu ball along with strength training on lower limb strength,
              dynamic balance, agility and functional performance in runners
              with lateral ankle instability. JCDR. 2021;15(5):YC01-YC06.
            </li>
            <li>
              Prajapat J, Kalra S*, Yadav J, Pal S, Pawaria S. Comparative Study
              Of Foam Rolling And Vibration Therapy On Blood Creatinine Level,
              Pain Sprint Speed, Lower Limb Power And Hip Range Of Motion In
              Delayed Onset Muscle Soreness. Int J Res Pharm Sci.
              2021;12(2):1-11
            </li>
            <li>
              Pal S, Kalra S, Pawaria S. De Quervain's Tenosynovitis in Weight
              Lifter: A Case Report.
            </li>
            <li>
              Pal S, Yadav J, Kalra S, Sindhu B. Different Training Approaches
              in Karate-A Review. Lond. J. Res. Hum. Soc. Sci. 2020;20:33-44.
            </li>
            <li>
              Pal S, Kalra S, Jain S. Covid-19 Pandemic: Mental Health and
              coping strategies. JCDR. 2020:14(12):YE01-YE04
            </li>
            <li>
              Pal S, Kalra S, Kumar S. A literature review on common injuries
              and their prevention in Kabaddi. European Journal of Sports &
              Exercise Science, 2020;9(1):01-09
            </li>
            <li>
              Sheetal Kalra*, Puneeta Ajmera, Joginder Yadav, Bijender Sindhu,
              Sonia Pawaria. Development of a valid and reliable questionnaire
              to identify factors affecting health of post menopausal women in
              selected areas of Gurugram India accepted for publication in
              Journal of Critical reviews. JCR. 2020;7(9):1150-1155
            </li>
            <li>
              Sajjan Pal, Joginder Yadav, Sheetal Kalra. Injury profile in
              Karate athletes-A literature review. Journal of Critical Reviews.
              2020; 7(9):110-1159
            </li>
            <li>
              Sonia Pawaria, Sheetal Kalra. Effect of Inspiratory muscle
              training and aerobic training on pulmonary function, exercise
              capacity and cardiorespiratory fitness in females with
              hypothyroidism accepted for publication in International journal
              of Research in Pharmaceutical Sciences. 2020:11(3)
            </li>
            <li>
              Kumar S, Panwar P, Garg S, Kalra S, Yadav J. Translation and
              cross-cultural adaptation of spinal cord independence measure
              version III in hindi language. Annals of Indian Academy of
              Neurology. 2020 Jan;23(1):98.
            </li>
            <li>
              Sonia Pawaria, Sheetal Kalra. Effect of Deep Cervical Flexor
              training on Respiratory functions in chronic neck pain patients
              with forward head posture. International journal of Research in
              Pharmaceutical Sciences. 2020; 11(3)
            </li>
            <li>
              Mohanta N, Kalra S*, Pawaria S. A Comparative Study of Circuit
              Training and Plyometric Training on Strength, Speed and Agility in
              State Level Lawn Tennis Players. JCDR. 2019;13(2):YC05-YC10.
            </li>
            <li>
              Amola M, Pawaria S, Kalra S*. Effect of Inspiratory Muscle
              Training and Diaphragmatic Breathing Exercises on Dyspnea,
              Pulmonary Functions, Fatigue and Functional Capacity in Pregnancy
              during Third Trimester. Journal of clinical and diagnostic
              research. 2019;13(8);YC01-YC04.
            </li>
            <li>
              Yadav P, Kalra S, Pawaria S*. Effect of pilates on agility and
              coordination skills in aspiring state level badminton players: a
              pilot study. Inter. Journal of Medical Science and Diagnosis
              Research. 2019.90-95.
            </li>
            <li>
              Preeti, Kalra S*, Pawaria S, Yadav J. Effect of Pilates on Lower
              Limb Strength, Dynamic Balance, Agility and Coordination Skills in
              Aspiring State Level Badminton Players. Journal of clinical and
              diagnostic research. 2019; 13(7):YC01-YC06.
            </li>
            <li>
              Pawaria S, Sudan D, Kalra S, Yadav J. Effectiveness of Cervical
              Stabilisation Exercises on Respiratory Strength in Chronic Neck
              Pain Patients with Forward Head Posture- A Pilot Study. Journal of
              clinical and diagnostic research. 2019;13(4):YC06-YC09.
            </li>
            <li>
              Pawaria S, Sudan D, Kalra S, Yadav J. Effectiveness of cervical
              stabilization exercises with feedback on respiratory status in
              chronic neck pain patients with forward head posture.
              International journal of Physiotherapy. 2019;6(3):70-74
            </li>
            <li>
              Tabassum Saher D, Kalra S, Yadav J, Thariwal S, Yadav K, Tanwar P.
              Role and importance of Physiotherapy during Palliative Care in
              India: A Review. WJRR. 2018;7(4):1-8
            </li>
            <li>
              Sachdeva S, Pawaria S, Kalra S. Effectiveness of pursed lip
              breathing versus mouth mask on dyspnea and functional capacity in
              acute exacerbation of chronic obstructive pulmonary disease.
              International journal of health sciences and Research. 2018;
              8(9):94-101.
            </li>
            <li>
              Sachdeva S, Pawaria S, Kalra S* Effects of muscle energy technique
              versus mobilization on pain and disability in post-partum females
              with sacroiliac joint dysfunction. Indian Journal of Health
              Sciences and care. 2018; 5(1):11-17
            </li>
            <li>
              Sachdeva S, Pawaria S, Kalra S. Effect of Pursed lip breathing and
              mouth mask on dyspnea on healthy individual-A Pilot study.
              International journal of health sciences and Research. 2018:8(7):
              135-140.
            </li>
            <li>
              Pancholi P, Yadav J, Kalra S*. Effect of resistance band exercises
              on neck pain, disability and forward head posture in dentists with
              chronic neck pain. J Physiother Rehabil. 2018;2(1).
            </li>
            <li>
              Raghav D, Yadav J, Kalra S. Effect of Thoraco-Lumbar Fascia
              Kinesio taping as a Therapeutic Intervention for Pain, Functional
              Disability in Non Specific Low Back Pain-A Pilot Study. Indian
              Journal of Health Sciences & Care. 2017; 4(1):9-13
            </li>
            <li>
              Pawaria S, Kalra S, Pal S. A study on cardiorespiratory fitness of
              Physiotherapy Students. Int J of Yoga, Physiotherapy and Physical
              education. 2017; 2(4):22-24.
            </li>
            <li>
              Kalra S*, Pal S, Pawaria S. Correlational study of chronic neck
              pain and hand grip strength in Physiotherapy Practioners. Int J of
              Yoga, Physiotherapy and Physical education. 2017; 2(4):30-32.
            </li>
            <li>
              Sharma V, Kalra S*. Pawaria S. Manual Physical Therapy in Patients
              with Myofascial Pain in Upper Trapezius: A Case Series. Indian J
              Physiotherapy & Occupational Therapy. 2016; 10(4):66-70.
            </li>
            <li>
              Kalra S*, Arora B, Pawaria S. Cross sectional survey of work
              related musculoskeletal disorders and associated risk factors
              among female farmers in rural areas of Gurgaon. Indian J
              Physiotherapy & Occupational therapy. 2016; 10(2);149-154
            </li>
            <li>
              Sharma V, Yadav J, Kalra S. Effect of square stepping exercises
              versus strength and balance training on balance and risk of fall
              in elderly population. Journal for Physiotherapy. 2016;
              104:140-47.
            </li>
            <li>
              Singhal S, Pawaria S, Kalra S. Effects of Aerobic versus
              resistance training on blood pressure in hypertensive patients.
              Journal of anesthesia and critical care. 2015;3(3):1-6.
            </li>
            <li>
              Pawaria S, Kalra S. A comparative study of myofascial release and
              stretching on pain, disability and range of motion in trapezius
              trigger points. Indian Journal of Health Sciences and Yadav J,
              Yadav J, Kalra S. Effect of Pelvic Floor Muscle Exercises in
              comparison with Core Stability Exercises on Pain, Functional
              status and Abdominal muscle endurance in Post-Partum females with
              Chronic Low back pain. Indian Journal of Health Sciences and Care.
              2015; 2(2):121-125.
            </li>
            <li>
              Khatwani P, Yadav J, Kalra S. The Effect of Cervical Lateral Glide
              and Manual Cervical Traction Combined with Neural Mobilization on
              Patients with Cervical Radiculopathy. Website: www. ijpot. com.
              2015 Oct;9(4):152.
            </li>
            <li>
              Vohra R, Kalra S*, Yadav J. A comparative study of static
              stretching and PNF stretching on pectoral muscle flexibility. Ind.
              J of Physio & Occup therapy. 2014; 8(3).
            </li>
            <li>
              Yadav A, Yadav J, Kalra S*. A comparative study of effectiveness
              between Myofascial release and Pressure release on pain and ankle
              range of motion in adults with soleus myofascial trigger points.
              Intl. J. of Orthopedics and Rehabilitation. 2014;1:61-66
            </li>
            <li>
              Popli S, Yadav J, Kalra S*. Effect of Static stretching versus
              retro walking on hamstring flexibility. Ind. J of Physio & Occup
              Therapy. 2014; (8):3.
            </li>
            <li>
              Sethi C, Kalra S*, Yadav J. A correlational study of BMI and
              fitness level among children in India in the age group of 13-16".
              J Sports and Physical Education, Photon. 2014; 3:169-174.
            </li>
            <li>
              Alam S, Kalra S, Mohapatra SC, Raii R, Raghav D. Frequency and
              Associated Factors of Physical Activity and Sleep with Depression
              in College Going Students. Journal of the Dow University of Health
              Sciences (JDUHS). 2021.
            </li>
            <li>
              Yadav S., Kalra S., Joginder Y., 2015. Effect of Forward Stair
              Climbing and Backward Locomotion on Cardiopulmonary Parameters in
              Sedentary Young Males. The Journal of Sports and Physical
              Education. Photon 112, 220-225.
            </li>
            <li>
              Sethi C, Kalra S, Yadav J. Correlation of body mass index and
              fitness level among school children in India in the age group of
              13-16 The Journal of Sports and Physical Education. Photon 111
              (2014) 169-174
            </li>
            <li>
              P. Shuklaa, B. Arorab*, S. Kalra. Effect of Treating BPPV Under
              Epleys Maneuver With Vestibular Rehabilitation Exercises and
              Brandt-Daroff Maneuver With Vestibular Rehabilitation Exercises In
              Elderly Population With Balance Impairment. Photon Journal of
              Neurology. Photon 103 (2015) 130-135
            </li>
          </ol>
          <br />

          <table border="1" cellpadding="8" cellspacing="0">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Title</th>
                <th>Publisher</th>
                <th>Year of Publication</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>
                  <strong>Book on</strong>
                  <br />
                  Health Psychology
                </td>
                <td>Vikas Publishers</td>
                <td>2021</td>
              </tr>
              <tr>
                <td>2</td>
                <td>
                  <strong>Book on</strong>
                  <br />
                  Healthcare Management
                </td>
                <td>Vikas Publishers</td>
                <td>2022</td>
              </tr>
              <tr>
                <td>3</td>
                <td>
                  <strong>Book on</strong>
                  <br />
                  Health and Positive Psychology
                </td>
                <td>Vikas Publishers</td>
                <td>2023</td>
              </tr>
              <tr>
                <td>4</td>
                <td>
                  <strong>Book Chapter</strong> Additive manufacturing in
                  healthcare
                </td>
                <td>Elsevier</td>
                <td>2023</td>
              </tr>
              <tr>
                <td>5</td>
                <td>
                  <strong>Book on</strong>
                  <br />
                  Recent Paradigms in Physiotherapy management
                </td>
                <td>Vikas Publishers</td>
                <td>2022</td>
              </tr>
              <tr>
                <td>6</td>
                <td>
                  <strong>Book Chapter</strong> Artificial intelligence assisted
                  telemedicine(book chapter)
                </td>
                <td>Taylor and Francis</td>
                <td>2023</td>
              </tr>
              <tr>
                <td>7</td>
                <td>
                  Handbook on augmenting telehealth services using artificial
                  intelligence
                </td>
                <td>Taylor and Francis</td>
                <td>2024</td>
              </tr>
              <tr>
                <td>8</td>
                <td>
                  <strong>Book on</strong>
                  <br />
                  Text book on Exercise Therapy
                </td>
                <td>CBS publishers</td>
                <td>2025</td>
              </tr>
              <tr>
                <td>9</td>
                <td>
                  <em>
                    Book chapter on "
                    <strong>
                      Machine learning and deep learning techniques for breast
                      cancer detection using ultrasound imaging
                    </strong>
                    "
                  </em>
                </td>
                <td>Academic Press</td>
                <td>2024</td>
              </tr>
            </tbody>
          </table>

          <br />
          <br />

          <h3>Copyrights</h3>
          <ol>
            <li>
              {" "}
              Afsha Parveen, Sheetal Kalra, Puneeta Ajmera, Deepali Bidhuri.
              Pilates Exercise protocol for sedentary adolescent girls. Reg
              No-L-138441/2023
            </li>
            <li>
              {" "}
              Sonia Pawaria, Sheetal Kalra. Integertaed Renal Rehabilitation
              program for Chronic Kidney Disease patients.
            </li>
            <li>
              {" "}
              Palka Mittal, Puneeta Ajmera, Sheetal Kalra. Screen time
              assessment L-158254-2024
            </li>
            <li>
              4. Sheetal Kalra, Puneeta Ajmera, Sonia Pawaria. Questioannaire to
              assess physical health, mental health and fitness of
              post-menopausal women. L-159901/2025
            </li>
          </ol>

          <br />
          <h3>Patent</h3>

          <ol>
            <li>
              {" "}
              Pelvic Inclinometer. Design no 404310-001. Granted on 11/01/24
              from the patent office. Govt of India
            </li>
            <li>
              Advanced Muscle Stimulator. Application no 202411011123. Govt of
              India(FILED)
            </li>
          </ol>
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() => setIsConferencesOpen(!isConferencesOpen)}
      >
        Invited Lecture
      </button>

      {isConferencesOpen ? (
        <div className="inner-container">
          <h2>Invited Lectures/Session Chaired/Moderator</h2>
  <table>
    <thead>
      <tr>
        <th>TOPIC</th>
        <th>PLACE</th>
        <th>YEAR</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Expert talk on Physiotherapy yesterday, today and Tomorrow</td>
        <td>Physiotherapy Convention by Physio help</td>
        <td>2016</td>
      </tr>
      <tr>
        <td>Expert talk on Women Health and Physiotherapy</td>
        <td>International Conference of Physical Therapy, AIIMS</td>
        <td>2016</td>
      </tr>
      <tr>
        <td>Expert Talk on "Female Athletic Triad"</td>
        <td>GCON-World Laproscopic Hospital</td>
        <td>2017</td>
      </tr>
      <tr>
        <td>Expert Talk on "Ergonomics for desk job workers" In a CME organized by Indian Association of Physiotherapy</td>
        <td>Sarvodaya Hospital Fardiabad</td>
        <td>2017</td>
      </tr>
      <tr>
        <td>Expert Talk on "Integrated Role of Yoga and Physiotherapy in Sports Injury prevention"</td>
        <td>Intergerated CAM Conference on Sports Medicine held in Jakarta Indonesia</td>
        <td>2018</td>
      </tr>
      <tr>
        <td>Expert Talk on "FAT"</td>
        <td>International Conference of Physical Therapy, AIIMS</td>
        <td>2018</td>
      </tr>
      <tr>
        <td>Expert Talk on "Role of yoga in Sports"</td>
        <td>International Orthopedic Conference Singapore</td>
        <td>2019</td>
      </tr>
      <tr>
        <td>Expert Talk on "Physiotherapy in lifestyle disorders"</td>
        <td>PHYSIO NEXT GEN CON by Indian Association of Physiotherapy(IAP)</td>
        <td>2019</td>
      </tr>
      <tr>
        <td>Expert Talk on "Pre participation Sports Evaluation-Perspectives of Sports Physio"</td>
        <td>International Conference of Physical Therapy, AIIMS</td>
        <td>2019</td>
      </tr>
      <tr>
        <td>Expert Talk on Women Health</td>
        <td>FORTIS Gurugram</td>
        <td>2019</td>
      </tr>
      <tr>
        <td>Panelist in "PAN INDIA ONLINE TALENT SEARCH"</td>
        <td>Organized by Indian Association of Physiotherapy (IAP) in May 2020.</td>
        <td>May 2020</td>
      </tr>
      <tr>
        <td>Panelist on topic "Current trends in physiotherapy education and clinical practices"</td>
        <td>Organized by international sports and orthopedics manual therapy (isomt).</td>
        <td>June 14 2020</td>
      </tr>
      <tr>
        <td>Expert Guest speaker webinar on "FITNESS TESTING IN SPORTS"</td>
        <td>Organized by Rehabilitation Sciences Group</td>
        <td>June 4, 2020</td>
      </tr>
      <tr>
        <td>Guest speaker -Online webinar on topic "Skill Fitness Testing in Sports"</td>
        <td>Organized by Indian Association of Physiotherapy</td>
        <td>25th July 2020</td>
      </tr>
      <tr>
        <td>Guest speaker -Online webinar on Topic "Physical Fitness during Covid-19" on the occasion of World Physiotherapy Day Celebration</td>
        <td>Physiotherapy Department AIIMS, New Delhi</td>
        <td>8th September 2020</td>
      </tr>
      <tr>
        <td>Guest speaker -online webinar on the topic "Physical activity for Physical and Mental Health" during Covid-19</td>
        <td>Haryana Chartered Association of Physiotherapy during National Haryana Physiotherapy Conference</td>
        <td>25th September 2020</td>
      </tr>
      <tr>
        <td>Panelist in Sports Conclave on the Topic "Sports Physiotherapists in India. Are we in Right direction"</td>
        <td>Organized during online conference-SPORTS Connect-</td>
        <td>14th Feb 2021</td>
      </tr>
      <tr>
        <td>Panelist in Panel discussion on "Telerehabilitation: Does it address the gap in sports injuries?"</td>
        <td>Indian Association of Physiotherapists</td>
        <td>8th Aug 2021</td>
      </tr>
      <tr>
        <td>Moderator in webinar on "Kinesiotaping in treatment and prevention of Sports Injuries"</td>
        <td>Indian Association of Physiotherapists</td>
        <td>18th September 2021</td>
      </tr>
      <tr>
        <td>Expert talk on Ethics in Physiotherapy Research; and chaired scientific session in the conference</td>
        <td>Santosh Physiotherapy College and Medical University</td>
        <td>4th September 2022</td>
      </tr>
      <tr>
        <td>Expert talk on Physiotherapy and yoga in sports injuries prevention</td>
        <td>PHYSIOHARCON, MDU Rohtak</td>
        <td>December 2022</td>
      </tr>
      <tr>
        <td>Expert talk on Physical activity for mobility and Independence in elderly</td>
        <td>GERICON, Geriatric medicine dept, AIIMS</td>
        <td>Sep 2023</td>
      </tr>
      <tr>
        <td>Expert talk on Ergonomics in Arthritis</td>
        <td>International Conference of Physical Therapy AIIMS, ND</td>
        <td>24th Dec 2024</td>
      </tr>
      <tr>
        <td>Chairperson for the session "MULTIDISCIPLINARY EXPLORATION" at INCPT AIIMS, 2024</td>
        <td>International Conference of Physical Therapy AIIMS, ND</td>
        <td>14 Dec 2024</td>
      </tr>
      <tr>
        <td>Expert Lecture on Key performance Indicators in sports</td>
        <td>Delhi Sports School, Delhi Sports University</td>
        <td>11th Dec 2024</td>
      </tr>
      <tr>
        <td>Speaker at International patient safety conference</td>
        <td>Indraparastha Apollo hospital at Bharatmandapam</td>
        <td>22nd Feb 2025</td>
      </tr>
      <tr>
        <td>Chairperson Physioconnect 5</td>
        <td>Sharda University Noida</td>
        <td>2nd Feb 2025</td>
      </tr>
      <tr>
        <td>Speaker in a Conference "Anti doping science-Innovations and challenges"</td>
        <td>NDTL INDIA</td>
        <td>19 March 2025</td>
      </tr>
    </tbody>
  </table>
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() =>
          setIsAwardsandRecognitionsOpen(!isAwardsandRecognitionsOpen)
        }
      >
        Paper Presentations
      </button>

      {isAwardsandRecognitionsOpen ? (
        <div className="inner-container">
          <p>
            <strong>Paper presentation</strong>
          </p>
          <ol>
            <li>
              Correlation of BMI and physical fitness in school children
              presented during INCPT AIIMS, ND.
            </li>
            <li>
              Paper presentation Respiratory response to BiPAP aided exercise in
              patients, with respiratory diseases during PHYSIOHARCON in Rohtak
              in the year 2016
            </li>
            <li>
              Paper presentation on Effect of Integrated Pelvic floor muscle
              exercises on Pain and disability in low back pain during post
              partum phase in 2017
            </li>
            <li>
              Paper presentation on physical fitness and mental health of post
              menopausal women in Gurugram in International Orthopedic
              Conference in Singapore 2018
            </li>
          </ol>
          
          <p>
            <strong>PROJECTS GRANTED</strong>
          </p>
          <ul>
            <li>
              <strong>
                Developing a mindful perinatal yoga and meditation program and
                determining its effectiveness on perinatal depression. Funded by
                ICSSR in April 2023. Amount sanctioned 9 Lakh rupees
              </strong>
            </li>
          </ul>
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() => setIsAwardsandRecOpen(!isAwardsandRecOpen)}
      >
        Awards and Recognition
      </button>

      {isAwardsandRecOpen ? (
        <div className="inner-container">
          <ul>
            <li>
              1. Award of Excellent contribution in Field of Physiotherapy
              PHYSIO-HARCON, Rohtak 2016
            </li>
            <li>2. Award of Young Achiever’s INCPT-6 AIIMS, New Delhi 2016</li>
            <li>
              3. Significant Contribution Award Conference by Indian Association
              of Physiotherapy 2016
            </li>
            <li>
              4. Award of Academic Excellence Physio Conference at World
              Laproscopic Hospital, Gurgaon 2017
            </li>
            <li>5. Academic Excellence award MEDANTA Hospital 2018</li>
            <li>
              6. Certificate of Appreciation for significant Contribution in
              Physiotherapy IAPWC at National Sports Club Delhi 2022 7
              Custodians of Humanity Award IGHM 2022
            </li>
          </ul>
        </div>
      ) : null}
    </div>
  );
};

export default DrSheetalKalra;
